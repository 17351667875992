import React from 'react';
import { motion } from 'framer-motion';

import { getMarketStyleOverridesForPerso } from './Perso';
import style from './perso.module.scss';
import { useSelector } from 'react-redux';

// Word wrapper
const Wrapper = props => {
	return (
		<span className={props.currentWord && props.currentWord[0] !== '\u00A0' ? style.wrapSpan : ''}>
			{props.children}
		</span>
	);
};

const tagMap = {
	product: 'h3',
	greeting: 'h1',
	description: 'h2'
};

const AnimatedCharacters = props => {
	const selector = state => {
		return {
			market: state.content.getIn(['content', 'market_details']).toJS()
		};
	};

	const { market } = useSelector(selector);

	const item = {
		hidden: {
			y: '300%',
			opacity: 0,
			transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 1.35 }
		},
		visible: {
			y: 0,
			opacity: 1,
			transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 1 }
		}
	};

	const splitWords = props.text.split(' ');

	const words = [];

	for (const [, item] of splitWords.entries()) {
		words.push(item.split(''));
		words.push(['\u00A0']);
	}

	words.map(word => {
		return word;
	});

	const Tag = tagMap[props.type];

	const reduceTextSize = words.some(x => x.length > 11);
	const reduceXLTextSize = words.some(x => x.length > 13);
	const marketOverrides = getMarketStyleOverridesForPerso(props.market.country);
	const shouldMarketOverride = marketOverrides !== undefined;

	const fontOverrides = () => {
		if (reduceXLTextSize) {
			return getNewFontSizes('100px', '80px');
		} else if (reduceTextSize) {
			return getNewFontSizes('120px', '100px');
		} else {
			return undefined;
		}
	};


	const sizing = fontOverrides() || {
		fontSize: shouldMarketOverride ? marketOverrides.greetingFontSize : '154px',
		lineHeight:
			shouldMarketOverride && market.country === 'US'
				? '110px'
				: '124px'
	};

	return (
		<Tag style={{ ...sizing }}>
			{words.map((word, index) => {
				const currentWord = words[index];

				if (currentWord[0] === '\u00A0') {
					return ' ';
				}

				return (
					<Wrapper key={index} currentWord={currentWord}>
						{words[index] &&
							words[index].flat().map((element, index) => {
								return (
									<span
										style={{
											overflow: 'hidden',
											display: 'inline-block'
										}}
										key={index}
									>
										<motion.span
											style={{
												display: 'inline-block'
											}}
											variants={item}
										>
											{element}
										</motion.span>
									</span>
								);
							})}
					</Wrapper>
				);
			})}
		</Tag>
	);
};

function getNewFontSizes(fontSize, lineHeight) {
	return {
		fontSize: fontSize,
		lineHeight: lineHeight
	};
}

export default AnimatedCharacters;
