const selector = state => {
	return {
		market: state.content.getIn(['content', 'market_details', 'country']).toLowerCase(),
		json: state.content.getIn(['content', 'pages']).toJS(),
		spinnerData: state.content.getIn(['content', 'flavours']).toJS(),
		qrState: state.content.getIn(['qrState']),
		actionState: state.content.getIn(['actionState']),
		actionVars: state.content.getIn(['actionVars']),
		storeId: state.content.getIn(['storeId']),
		gameId: state.content.getIn(['gameId']),
		qrURL: state.content.getIn(['qrURL']),
		prize: state.content.getIn(['prize']),
		winningFlavour: state.content.getIn(['winningFlavour']),
		landingFlavour: state.content.getIn(['landingFlavour'])
	};
};

export default selector;
