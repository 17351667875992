import { Map, fromJS } from 'immutable';
import { apiRequest } from 'bat-components';
import ReactGA from 'react-ga';
import queryString from 'query-string';

const { hostname } = window.location;

const rand = (minNo, maxNo) => {
	const difference = maxNo - minNo + 1;
	const randomNo = Math.floor(Math.random() * difference);
	return randomNo + minNo;
};

const devMode = process.env.NODE_ENV === 'development'; // dev mode allows playing without a store id / game id

let timeoutUser = 1000 * 60; // 60 - maximum allowed time between user interactions
let timeoutEndGame = 1000 * 12; // 12 - how long to show result before finishing

if (devMode) {
	timeoutUser = 1000 * 10;
	timeoutEndGame = 1000 * 10;
}

let qrDomain = '';
if (hostname.match('localhost')) {
	qrDomain = 'http://192.168.0.200:3001';
} else if (hostname.match('vype.kerve.com')) {
	qrDomain = 'http://samsung-game.vype.kerve.com';
} else if (hostname.match('futuremaker.kerve.com')) {
	qrDomain = 'https://samsung-game.futuremaker.kerve.com';
} else if (hostname.match('futuremaker.com')) {
	qrDomain = 'https://samsung-game.futuremaker.com';
}

export const content = {
	state: Map({
		content: null,
		qrState: null, // ready, playing, expired
		actionState: null, // newGame, cancelGame, spinToWin
		actionVars: null,
		gameId: null,
		qrURL: null,
		qrTimeout: null,
		newGameTimeout: null,
		endGameTimeout: null,
		prize: false,
		winningFlavour: null,
		landingFlavour: null
	}),

	reducers: {
		setInitialState(state, payload) {
			return state.setIn(['content'], fromJS(payload));
		},
		setWinningFlavour(state, payload) {
			return state.setIn(['winningFlavour'], payload);
		},
		setQrState(state, payload) {
			console.log('qrState', payload);
			return state.setIn(['qrState'], payload);
		},

		setActionState(state, payload) {
			console.log('actionState', payload);
			return state.setIn(['actionState'], payload);
		},
		setActionVars(state, payload) {
			return state.setIn(['actionVars'], payload);
		},
		setGameId(state, payload) {
			const id = devMode ? 'devGameId' : payload;
			return state.setIn(['gameId'], id);
		},
		setQrURL(state, payload) {
			console.log('qrURL', payload);
			return state.setIn(['qrURL'], payload);
		},
		setQrTimeout(state, payload) {
			return state.setIn(['qrTimeout'], payload);
		},
		setNewGameTimeout(state, payload) {
			return state.setIn(['newGameTimeout'], payload);
		},
		setEndGameTimeout(state, payload) {
			return state.setIn(['endGameTimeout'], payload);
		},
		setPrize(state, payload) {
			return state.setIn(['prize'], payload);
		},
		setLandingFlavour(state, payload) {
			return state.setIn(['landingFlavour'], payload);
		}
	},

	effects: dispatch => ({
		getWinningFlavour(payload, rootState) {
			const flavours = rootState.content.getIn(['content', 'flavours']).toJS();
			const first = flavours[0].name;
			dispatch.content.setWinningFlavour(first);
		},
		generateQr(payload, rootState) {
			console.log('%c--- generateQr ---', 'color:#3182e0');
			const timestamp = Date.now().toString().substring(4);
			const random = rand(111, 999);
			const gameId = timestamp.toString() + random.toString() + 'qr';
			const storeId = rootState.internal.getIn(['store', 'uuid']);
			const { store_miid } = queryString.parse(window.location.search);

			if (devMode === true || typeof storeId !== 'undefined') {
				apiRequest(
					'spintowin/save',
					{
						store: storeId,
						identity: gameId,
						game_type: 'spin2win_v1'
					},
					response => {
						if (response.status === 'ok') {
							dispatch.content.setGameId(gameId);
							dispatch.content.setQrURL(
								`${qrDomain}?store_miid=${store_miid}&touch_point=game&g=${gameId}`
							);
							dispatch.content.setQrState('ready');
							ReactGA.event({
								category: 'General',
								action: 'API Success - spintowin/save'
							});
						} else {
							dispatch.content.logError({
								message_code: 'error.welcomeTV.generateQr.1',
								message: 'API Error - /spintowin/save',
								post_vars: response
							});
							setTimeout(function () {
								dispatch.content.generateQr();
								// ReactGA.event({
								// 	category: 'General',
								// 	action: 'API Error - spintowin/save'
								// });
							}, 2000);
						}
					}
				);
			} else {
				dispatch.content.logError({
					message_code: 'error.welcomeTV.generateQr.2',
					message: 'Could not generate QR code'
				});
			}
		},
		newGame(payload, rootState) {
			const qrState = rootState.content.getIn(['qrState']);
			if (qrState === 'ready') {
				const actualStoreId = rootState.internal.getIn(['store', 'uuid']);
				const payloadStoreId = payload.storeId;
				const actualGameId = rootState.content.getIn(['gameId']);
				const payloadGameId = payload.gameId;

				if (
					actualStoreId === payloadStoreId &&
					(actualGameId === payloadGameId || (devMode === true && payloadGameId === 'devtest'))
				) {
					console.log('%c--- newGame - valid ---', 'color:#3182e0');
					clearTimeout(rootState.content.getIn(['qrTimeout']));
					dispatch.content.setQrState('playing');
					dispatch.content.setActionState('newGame');
					dispatch.content.confirmGame({ sendToRoom: payload.sendToRoom });
					dispatch.content.newGameTimeout();
					ReactGA.event({
						category: 'New Game',
						action: 'Valid'
					});
				} else {
					console.log('%c--- newGame - invalid 1 ---', 'color:#3182e0');
					dispatch.content.denyGame({ sendToRoom: payload.sendToRoom });
					dispatch.content.logError({
						message_code: 'error.welcomeTV.newGame.1',
						message: 'QR mismatch, could not start game'
					});
					// ReactGA.event({
					// 	category: 'New Game',
					// 	action: 'Invalid 1'
					// });
				}
			} else {
				console.log('%c--- newGame - invalid 2 ---', 'color:#3182e0');
				dispatch.content.denyGame({ sendToRoom: payload.sendToRoom });
				dispatch.content.logError({
					message_code: 'error.welcomeTV.newGame.2',
					message: 'QR not ready, could not start game'
				});
				// ReactGA.event({
				// 	category: 'New Game',
				// 	action: 'Invalid 2'
				// });
			}
			// to do - check if currently showing promo or screensaver and animate as required
		},
		confirmGame(payload, rootState) {
			payload.sendToRoom('confirmGame', {
				storeId: rootState.internal.getIn(['store', 'uuid']),
				gameId: rootState.content.getIn(['gameId'])
			});
		},
		denyGame(payload, rootState) {
			payload.sendToRoom('denyGame', {
				storeId: rootState.internal.getIn(['store', 'uuid']),
				gameId: rootState.content.getIn(['gameId'])
			});
		},
		newGameTimeout(payload, rootState) {
			console.log('%c--- newGameTimeout ---', 'color:#3182e0');
			clearTimeout(rootState.content.getIn(['newGameTimeout']));
			dispatch.content.setNewGameTimeout(
				setTimeout(function () {
					dispatch.content.cancelGame();
				}, timeoutUser)
			);
		},
		refreshNewGameTimeout(payload, rootState) {
			const actualStoreId = rootState.internal.getIn(['store', 'uuid']);
			const payloadStoreId = payload.storeId;
			const actualGameId = rootState.content.getIn(['gameId']);
			const payloadGameId = payload.gameId;
			if (
				actualStoreId === payloadStoreId &&
				(actualGameId === payloadGameId || (devMode === true && payloadGameId === 'devtest'))
			) {
				dispatch.content.newGameTimeout();
			}
		},
		cancelGame(payload, rootState) {
			const qrState = rootState.content.getIn(['qrState']);
			if (qrState === 'playing') {
				console.log('%c--- cancelGame ---', 'color:#3182e0');
				dispatch.content.setQrState('expired');
				dispatch.content.setActionState('cancelGame');
				dispatch.content.generateQr();
				dispatch.content.logError({
					message_code: 'error.welcomeTV.cancelGame.1',
					message: 'Game timed out due to inactivity'
				});
				ReactGA.event({
					category: 'General',
					action: 'Game timed out due to inactivity'
				});
			}
		},
		quitGame(payload, rootState) {
			const actualStoreId = rootState.internal.getIn(['store', 'uuid']);
			const payloadStoreId = payload.storeId;
			const actualGameId = rootState.content.getIn(['gameId']);
			const payloadGameId = payload.gameId;
			if (
				actualStoreId === payloadStoreId &&
				(actualGameId === payloadGameId || (devMode === true && payloadGameId === 'devtest'))
			) {
				console.log('%c--- quitGame ---', 'color:#3182e0');
				dispatch.content.cancelGame();
			}
		},
		spinToWin(payload, rootState) {
			const qrState = rootState.content.getIn(['qrState']);
			if (qrState === 'playing') {
				const actualStoreId = rootState.internal.getIn(['store', 'uuid']);
				const payloadStoreId = payload.storeId;
				const actualGameId = rootState.content.getIn(['gameId']);
				const payloadGameId = payload.gameId;
				if (
					actualStoreId === payloadStoreId &&
					(actualGameId === payloadGameId || (devMode === true && payloadGameId === 'devtest'))
				) {
					console.log('%c--- spinToWin ---', 'color:#3182e0');
					console.log(payload);
					clearTimeout(rootState.content.getIn(['newGameTimeout']));

					const landingFlavour = rootState.content
						.getIn(['content', 'flavours', payload.sector])
						.toJS().name;
					dispatch.content.setPrize(payload.success);
					dispatch.content.setLandingFlavour(landingFlavour);
					dispatch.content.setActionVars(payload);
					dispatch.content.setActionState('spinToWin');
					dispatch.content.setQrState('expired');
					dispatch.content.endGameTimeout();
				}
			}
		},
		endGameTimeout(payload, rootState) {
			console.log('%c--- endGameTimeout ---', 'color:#3182e0');
			clearTimeout(rootState.content.getIn(['endGameTimeout']));
			dispatch.content.setEndGameTimeout(
				setTimeout(function () {
					dispatch.content.endGame();
				}, timeoutEndGame)
			);
		},
		endGame(payload, rootState) {
			console.log('%c--- endGame ---', 'color:#3182e0');
			dispatch.content.setQrState('expired');
			dispatch.content.setActionState('endGame');
			dispatch.content.generateQr();
		},
		logError(payload, rootState) {
			try {
				console.log('%cERROR LOG', 'color:#f00');
				payload.store = rootState.internal.getIn(['store', 'uuid']);
				payload.version = rootState.content.getIn(['content', 'version']);
				console.log(payload);
				apiRequest('log', payload, function () {});
			} catch (e) {
				//
			}
		}
	})
};
