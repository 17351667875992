import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import ReactGA from 'react-ga';

import { MediaSlider, Layout } from 'bat-components';
import welcomeSelector from './Welcome.selector';
import * as animations from './Welcome.animations';
import { Game } from 'components/pages';

import style from './welcome.module.scss';
import PersoComponent from '../Perso/Perso';
import { useQueryParams } from 'bat-components';

export function Welcome() {
	const { slides } = useSelector(welcomeSelector);
	const gameSlideExists = Object.keys(slides).some(k => {
		return slides[k].type === 'game';
	});
	const [gameState, setGameState] = useState(gameSlideExists ? 'hidden' : 'disabled');
	const [sliderPlayMode, setSliderPlayMode] = useState(slides.length === 1 ? 'paused' : 'playing');
	const gameCallback = useCallback(response => {
		// console.log('%c--- gameCallback - ' + response + ' ---', 'color:#e03170');
		switch (response) {
			case 'endAnimation':
				setSliderPlayMode('playing');
				setGameState('hidden');
				break;
			case 'startGame':
				setGameState('startGame');
				setSliderPlayMode('paused');
				break;
			case 'endGame':
				setSliderPlayMode('playing');
				setGameState('hidden');
				break;
			default:
				break;
		}
	}, []);

	const slideChange = slide => {
		if (slide.type === 'game') {
			setSliderPlayMode('paused');
			setGameState('startAnimation');
		}

		ReactGA.event({
			category: 'Screen Slides',
			action: slide.type,
			label: slide.type === 'game' ? '' : slide.content
		});
	};

	const { slide } = useQueryParams();

	const setSliderMedia = () => {
		if (slide === 'perso') {
			return [slides.find(s => s.type === slide)];
		}
		return slides;
	};

	return (
		<Layout>
			<AnimatePresence initial={false}>
				{gameState !== 'startGame' && (
					<motion.div
						key="slider"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<MediaSlider
							media={setSliderMedia()}
							customComponents={{
								game: GameComponent,
								perso: PersoComponent
							}}
							onSlideChange={slideChange}
							playState={sliderPlayMode}
						/>
					</motion.div>
				)}
			</AnimatePresence>
			{gameState === 'disabled' ? null : <Game gameState={gameState} gameCallback={gameCallback} />}
		</Layout>
	);
}

function GameComponent() {
	return (
		<motion.div
			className={style.eventWrapper}
			initial="from"
			animate="to"
			variants={animations.animVariants}
		></motion.div>
	);
}
