import React, { useEffect, useRef } from 'react'; //useState
import style from './spinner.module.scss';

import * as vars from './vars';
import * as canvas from './canvas';
import * as ani from './animate';
import * as calc from './calculate';

export function Spinner({ spinnerType, spinnerCallback, spinnerData, spinnerState, spinnerVars }) {
	// ======================================================================
	// STATE
	// ======================================================================

	// canvas
	const canvasRef1 = useRef();
	const canvasRef2 = useRef();

	// ======================================================================
	// INITIALISE
	// ======================================================================

	useEffect(() => {
		console.log('%c*** spinner initialise - ' + spinnerType + ' ***', 'font-weight:bold;');

		// reset animation timeouts and tweens
		ani.ani_reset_all_timeouts();
		ani.ani_reset_all_tweens();

		// reset vars
		vars.init(spinnerType, spinnerCallback);

		// process data
		calc.calculate_init(spinnerData);

		// reset animations
		ani.ani_reset_all();

		// initiate canvas drawing
		canvas.canvas_init(canvasRef1, canvasRef2);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ======================================================================
	// GAME ACTIONS
	// ======================================================================

	useEffect(() => {
		// let spinData;
		switch (spinnerState) {
			case 'reset':
				// stop animating and reset spinner
				ani.ani_reset_all();
				break;
			case 'animate':
				// runs the screen saver animation
				ani.ani_animate_sectors(spinnerCallback);
				break;
			case 'welcomeReady':
				// spins into position ready to play
				ani.ani_welcome_ready();
				break;
			case 'welcomeCancel':
				// game cancelled - hide the spinner
				ani.ani_welcome_cancel();
				break;
			case 'spinToWin':
				// play the game
				ani.ani_spin_to(spinnerVars, spinnerCallback);
				break;
			// case 'spinClockwiseWin':
			// 	spinData = ani.ani_spin('clockwise', true);
			// 	ani.ani_spin_to(spinData, spinnerCallback);
			// 	spinnerCallback('returnSpinToWin', spinData);
			// 	break;
			// case 'spinClockwiseLose':
			// 	spinData = ani.ani_spin('clockwise', false);
			// 	ani.ani_spin_to(spinData, spinnerCallback);
			// 	spinnerCallback('returnSpinToWin', spinData);
			// 	break;
			// case 'spinAnticlockwiseWin':
			// 	spinData = ani.ani_spin('anticlockwise', true);
			// 	ani.ani_spin_to(spinData, spinnerCallback);
			// 	spinnerCallback('returnSpinToWin', spinData);
			// 	break;
			// case 'spinAnticlockwiseLose':
			// 	spinData = ani.ani_spin('anticlockwise', false);
			// 	ani.ani_spin_to(spinData, spinnerCallback);
			// 	spinnerCallback('returnSpinToWin', spinData);
			// 	break;
			// case 'gameReady':
			// 	ani.ani_game_ready(spinnerCallback);
			// 	break;
			// case 'gameComplete':
			// 	ani.ani_game_complete();
			// 	break;
			// case 'spinTest':
			// 	var angle = helper.rand(0, 5000) - 2500;
			// 	var id = ani.ani_snap_to_angle(angle);
			// 	console.log('Span to ' + id);
			// 	break;
			default:
				break;
		}
	}, [spinnerState, spinnerCallback, spinnerVars]);

	useEffect(() => {
		if (spinnerState === 'spinToAngle' && typeof spinnerVars === 'number') {
			ani.ani_set_rotation_instant(spinnerVars);
		}
	}, [spinnerState, spinnerVars]);

	// ======================================================================
	// RENDER
	// ======================================================================

	const outerClass = style.spinner_outer + ' ' + style[spinnerType];

	return (
		<div>
			<div id={'spinner_outer_' + spinnerType} className={outerClass}>
				<div id={'spinner_inner_' + spinnerType} className={style.spinner_inner}>
					<canvas ref={canvasRef1} className={style.canvas}></canvas>
					<canvas ref={canvasRef2} className={style.canvas}></canvas>
				</div>
			</div>
		</div>
	);
}
