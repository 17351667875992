// ======================================================================
// IMPORTS
// ======================================================================

import { TweenMax } from 'gsap/TweenMax';
import { state, options, sectors } from './vars';
import * as helper from './helpers';

// ======================================================================
// LOGS
// ======================================================================

const devLog = function () {}; // console.log;

// ======================================================================
// INIT
// ======================================================================

export function calculate_init(data) {
	calculate_sectors(data);
	calculate_all_dark_elements();
	calculate_all_coloured_elements();
	state.inited = true;
}

// ======================================================================
// UPDATE
// ======================================================================

export function calculate_updates(instant, result) {
	// calculate
	calculate_all_coloured_elements(instant, result);
	if (state.env === 'game') {
		calculate_all_dark_elements(instant, result);
	}
}

// ======================================================================
// CALCULATE COLOURED SECTORS
// ======================================================================

function calculate_sectors(spinnerData) {
	devLog('%c--- calculate_sectors ---', 'color:#3cbf21;');
	var sectors = [];
	for (let i in spinnerData) {
		let sector = helper.clone(spinnerData[i]);
		sector.type = 'slice';
		if (typeof sector.fill === 'undefined' && typeof sector.color_flavour !== 'undefined') {
			sector.fill = sector.color_flavour;
		}
		sectors.push(sector);
	}
	calculate_all_sectors(sectors);
}

// ======================================================================
// CALCULATE ALL SECTORS
// ======================================================================

// TURN OPTIONS INTO AN ARRAY OF SECTORS
function calculate_all_sectors(spinnerData) {
	devLog('%c--- calculate_all_sectors ---', 'color:#3cbf21;');
	// vars
	var total = spinnerData.length;
	var setupState1 = [];
	var setupState2 = [];

	// merge API data with sector data
	var D = sectors;
	D.coloured_sectors = spinnerData;

	// check for minimum counts and multiply sectors if necessary
	var minimum = null;
	for (let i in options.minimums) {
		if (total <= i) {
			minimum = i;
			break;
		}
	}
	if (minimum != null) {
		var minimum_multiplier = options.minimums[minimum];
		var new_sectors = [];
		for (let j = 0; j < minimum_multiplier; j++) {
			for (let k in D.coloured_sectors) {
				new_sectors.push(helper.clone(D.coloured_sectors[k]));
			}
		}
		D.coloured_sectors = new_sectors;
		total = D.coloured_sectors.length;
	}

	// generate array of elements
	for (let i = 0; i < total; i++) {
		// key
		var key = i;

		// coloured slices
		var slice = D.coloured_sectors[i];
		slice.key = key;
		slice.main = true;
		setupState2.push(slice);

		if (options.shards_show) {
			// coloured shards
			if (D.coloured_shards.length > 0) {
				var shard1 = D.coloured_shards[i % D.coloured_shards.length];
				if (shard1 != null) {
					shard1 = helper.clone(shard1);
					shard1.key = key;
					setupState2.push(shard1);
				}
			}

			// coloured triangles
			if (D.coloured_triangles.length > 0) {
				var triangle = D.coloured_triangles[i % D.coloured_triangles.length];
				if (triangle != null) {
					triangle = helper.clone(triangle);
					triangle.key = key;
					setupState2.push(triangle);
				}
			}
		}

		if (options.dark_show) {
			// dark slices
			if (D.dark_sectors.length > 0) {
				var dark = D.dark_sectors[i % D.dark_sectors.length];
				if (dark != null) {
					dark = helper.clone(dark);
					dark.key = key;
					dark.main = true;
					setupState1.push(dark);
				}
			}

			// dark shards
			if (D.dark_shards.length > 0) {
				var shard2 = D.dark_shards[i % D.dark_shards.length];
				if (shard2 != null) {
					shard2 = helper.clone(shard2);
					shard2.key = key;
					setupState1.push(shard2);
				}
			}
		}
	}

	// middle
	if (options.middle_show) {
		var middle = helper.clone(options.middle);
		setupState2.push(middle);
	}

	// save
	state.canvas_radius = options.canvas_diameter / 2;
	state.spinner1_radius = options.spinner1_diameter / 2;
	state.spinner2_radius = options.spinner2_diameter / 2;
	state.setupState1 = setupState1;
	state.setupState2 = setupState2;
	state.total_sectors = total;
}

// ======================================================================
// CALCULATE ELEMENT STATES
// ======================================================================

// LOOP THROUGH ARRAY OF SECTORS AND CREATE DATA TO PRODUCE CANVAS IMAGE
function calculate_all_dark_elements(instant, result) {
	devLog('%c--- calculate_all_dark_elements ---', 'color:#3cbf21;');
	if (options.dark_show) {
		calculate_all_elements(1, instant, result);
	}
}

function calculate_all_coloured_elements(instant, result) {
	devLog('%c--- calculate_all_coloured_elements ---', 'color:#3cbf21;');
	calculate_all_elements(2, instant, result);
}

function calculate_all_elements(id, instant, result) {
	// check for highlighted item
	var highlight = state.highlight_sector;
	var highlight_angle = result === true ? options.highlight_angle2 : options.highlight_angle;

	// angle
	var total = state.total_sectors;
	var original_angle = (1 / total) * 360;
	var new_angle = original_angle;
	if (highlight !== null) {
		new_angle = (1 / (total - 1)) * (360 - highlight_angle);
	}

	// calculate initial rotation
	var R;
	if (highlight === null) {
		R = -(original_angle / 2);
	} else if (highlight !== null) {
		var orig_center = highlight * original_angle;
		var new_center = highlight * new_angle;
		R = orig_center - new_center - highlight_angle / 2;
	}

	// loop sectors
	var count = 0;
	var sectorData = [];
	for (let i in state['setupState' + id]) {
		var sector = state['setupState' + id][i];

		// angle of current sector
		var sectorAngle = new_angle;
		if (count === highlight) {
			sectorAngle = highlight_angle;
		}

		// overlap sectors slightly to avoid pixelated gaps
		var adjustedSectorAngle = sectorAngle;
		if (!options.stroke_show) {
			if (count === highlight) {
				adjustedSectorAngle = adjustedSectorAngle * 1.0015;
			} else {
				adjustedSectorAngle = adjustedSectorAngle * 1.01;
			}
		}

		// radius of current sector
		var sectorRadius = id === 1 ? state.spinner1_radius : state.spinner2_radius;
		if (count === highlight) {
			sectorRadius = sectorRadius * options.highlight_radius_multiplier;
		}

		// clone sector object
		var sectorItem = helper.clone(sector);

		// slices
		if (sectorItem.type === 'slice') {
			var sliceData = calculate_slice(sector, sectorRadius, adjustedSectorAngle, R);
			sectorItem.data = sliceData.data;
			sectorItem.grad = sliceData.grad;
		}

		// triangles
		if (sectorItem.type === 'triangle') {
			var triangleData = calculate_triangle(sector, sectorRadius, adjustedSectorAngle, R);
			sectorItem.data = triangleData.data;
			sectorItem.grad = triangleData.grad;
		}

		// save
		sectorData.push(sectorItem);

		// increment
		var nextKey = parseInt(i) + 1;
		if (
			typeof state['setupState' + id][nextKey] !== 'undefined' &&
			typeof state['setupState' + id][nextKey].main !== 'undefined'
		) {
			R = R + sectorAngle;
			count++;
		}
	}

	// save common states once only
	if (!state.inited) {
		// default visible state
		state['defaultState' + id] = helper.clone(sectorData);
		// current state
		if (options.hide_colors_on_init && id === 2) {
			var hiddenState = helper.clone(sectorData);
			for (let i in hiddenState) {
				if (hiddenState[i].type === 'slice') {
					hiddenState[i].data.angle_end = hiddenState[i].data.angle_start;
				} else if (hiddenState[i].type === 'triangle') {
					hiddenState[i].data.p3x = hiddenState[i].data.p2x;
					hiddenState[i].data.p3y = hiddenState[i].data.p2y;
				}
			}
			state['currentState' + id] = helper.clone(hiddenState);
		} else {
			state['currentState' + id] = helper.clone(sectorData);
		}
		// future state
		state['futureState' + id] = helper.clone(sectorData);
	}

	// save / tween to new states
	if (state.inited) {
		// current state
		if (instant === true) {
			state['currentState' + id] = sectorData;
		} else {
			for (let i in state['currentState' + id]) {
				var oldGroup = state['currentState' + id][i].data;
				var newGroup = sectorData[i].data;
				newGroup.ease = 'Power2.easeInOut';
				TweenMax.to(oldGroup, options.time_highlight_transition / 1000, newGroup);
			}
		}
		// future state
		state['futureState' + id] = helper.clone(sectorData);
	}
}

// CALCULATE DATA FOR A SLICE
function calculate_slice(element, radius, angle, R) {
	var L = state.canvas_radius;

	// calculate angles
	var angle_start = R;
	var angle_end = R + angle;

	// adjust angles if slice is a narrow shard
	if (typeof element.width !== 'undefined') {
		if (element.side === 'right') {
			angle_start = angle_start + (angle - angle * element.width);
		} else {
			angle_end = R + angle * element.width;
		}
	}

	// calculate gradient direction
	var angle_mid = angle_start + (angle_end - angle_start) / 2;
	var gradX = L + L * Math.sin(helper.toRadians(angle_mid));
	var gradY = L - L * Math.cos(helper.toRadians(angle_mid));
	var grad = [gradX, gradY];

	// console.log({
	// 	grad1: [L, L],
	// 	grad2: grad
	// });

	// return
	return {
		data: {
			radius: radius,
			angle_start: helper.toRadians(angle_start - 90),
			angle_end: helper.toRadians(angle_end - 90)
		},
		grad: {
			grad1: [L, L],
			grad2: grad
		}
	};
}

// CALCULATE DATA FOR A TRIANGLE
function calculate_triangle(element, radius, angle, R) {
	var L1 = state.canvas_radius;
	var L2 = radius;
	var left = element.left;
	var right = element.right;

	//
	if (state.env === 'welcome') {
		left = element.left * 0.5;
		right = element.right * 0.5;
	}

	// calculate triangle coordinates
	var p1 = [L1, L1];
	var p2 = rotate_point([L1, L1], [L1, L1 - L2 * left], R);
	var p3 = rotate_point([L1, L1], [L1, L1 - L2 * right], R + angle);

	// calculate gradient direction
	var max_side = left > right ? left : right;
	var grad = rotate_point([L1, L1], [L1, L1 - L2 * max_side], R + angle);

	// return
	return {
		data: {
			p1x: p1[0],
			p1y: p1[1],
			p2x: p2[0],
			p2y: p2[1],
			p3x: p3[0],
			p3y: p3[1]
		},
		grad: {
			grad1: [L1, L1],
			grad2: grad
		}
	};
}

// ROTATE A POINT ABOUT A CENTER POINT
function rotate_point(center, point, angle) {
	angle = helper.toRadians(angle);
	var x1 = point[0] - center[0];
	var y1 = point[1] - center[1];
	var x2 = x1 * Math.cos(angle) - y1 * Math.sin(angle);
	var y2 = x1 * Math.sin(angle) + y1 * Math.cos(angle);
	point[0] = x2 + center[0];
	point[1] = y2 + center[1];
	return point;
}
