export const animVariants = {
	from: {
		scale: 1.01
	},
	to: {
		scale: 1,
		transition: { duration: 0.8, when: 'beforeChildren' }
	}
};

export const contentVariantsFromRight = {
	from: {
		opacity: 0,
		right: -40
	},
	to: {
		opacity: 1,
		right: 0
	}
};

export const prefixVariants = {
	from: {
		opacity: 0,
		bottom: -10
	},
	to: {
		opacity: 1,
		bottom: 0
	}
};

export const contentVariants = {
	from: {
		opacity: 0
	},
	to: {
		opacity: 1
	}
};

export const transitionPrefix = { duration: 0.6, delay: 0.5, ease: 'easeInOut' };
export const transitionMainTitle = { duration: 0.8, ease: 'circOut' };
export const transitionDate = { duration: 0.8, delay: 1.2, ease: 'easeInOut' };
export const transitionBody = { duration: 0.8, delay: 1.2, ease: 'easeInOut' };
