import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import QRCode from 'qrcode.react';
import clsx from 'clsx';

import { useSocket } from 'bat-components';
import { Spinner } from 'components/partials';

import gameSelector from './Game.selector';
import style from './game.module.scss';

let cancelGameTimeout;
let deviceTimeout;
let spinCompleteTimeout;
let endGameTimeoutValue;

export function Game({ gameState, gameCallback }) {
	const { actionState, actionVars, ...props } = useSelector(gameSelector);
	const { state: socketState } = useSocket();
	const dispatch = useDispatch();

	const endGameTimeout = dispatch.content.endGameTimeout;

	// ======================================================================
	// VARS
	// ======================================================================

	const [playState, setPlayState] = useState('promo'); // promo, playing, result, cancelling
	const [spinnerState, setSpinnerState] = useState(null);
	const [spinnerVars, setSpinnerVars] = useState(null);
	const [dropState, setDropState] = useState(null);
	const [deviceState, setDeviceState] = useState(null);
	const [resultState, setResultState] = useState(null);

	// ======================================================================
	// EFFECTS
	// ======================================================================

	useEffect(() => {
		// console.log('%c--- gameState - ' + gameState + ' ---', 'color:#e03170');
		if (gameState === 'startAnimation') {
			animate();
		}
	}, [gameState]);

	// ======================================================================
	// EVENTS
	// ======================================================================

	// start screensaver animation
	const animate = () => {
		setPlayState('promo');
		setSpinnerState('animate');
	};

	// screensaver animation has ended
	const animateComplete = useCallback(() => {
		setPlayState('hidden');
		setSpinnerState(null);
		gameCallback('endAnimation');
	}, [gameCallback]);

	// start a new game
	const newGame = useCallback(() => {
		gameCallback('startGame');
		setPlayState('playing');
		setSpinnerState('welcomeReady');
		deviceTimeout = setTimeout(() => {
			setDeviceState('visible');
		}, 1400);
	}, [gameCallback]);

	// new game timeout due to inactivity
	const cancelGame = useCallback(() => {
		setDeviceState('hidden');
		setResultState('hidden');
		setPlayState('cancelling');
		setSpinnerState('welcomeCancel');
		cancelGameTimeout = setTimeout(() => {
			animateComplete();
		}, 3000);
	}, [animateComplete]);

	// user has spun to a result
	const spinComplete = useCallback(() => {
		setPlayState('result');
		spinCompleteTimeout = setTimeout(() => {
			endGameTimeout();
			setResultState('visible');
		}, 350);
	}, [endGameTimeout]);

	// result has been shown and game has now ended
	const endGame = useCallback(() => {
		setDeviceState('end');
		setResultState('end');
		setSpinnerState('reset');

		endGameTimeoutValue = setTimeout(() => {
			setDeviceState('hidden');
			setResultState('hidden');
			setPlayState('hidden');
			setSpinnerState(null);

			// This ha to be last to prevent a weird issue on inside welcome screen
			gameCallback('endGame');
		}, 500);
	}, [gameCallback]);

	window.setSpinnerState = setSpinnerState;

	// ======================================================================
	// SPINNER CALLBACK
	// ======================================================================

	const spinnerCallback = useCallback(
		(method, data) => {
			switch (method) {
				case 'returnAnimateComplete':
					animateComplete();
					break;
				case 'returnSpinComplete':
					spinComplete();
					break;
				case 'dropState':
					setDropState(data);
					break;
				default:
					break;
			}
		},
		[animateComplete, spinComplete]
	);

	useEffect(() => {
		switch (actionState) {
			case 'newGame':
				clearTimeout(cancelGameTimeout);
				clearTimeout(deviceTimeout);
				clearTimeout(spinCompleteTimeout);
				clearTimeout(endGameTimeoutValue);
				newGame();
				break;
			case 'cancelGame':
				cancelGame();
				break;
			case 'spinToWin':
				setSpinnerVars(actionVars);
				setSpinnerState('spinToWin');
				break;
			case 'endGame':
				endGame();
				break;
			default:
				break;
		}
	}, [actionState, actionVars, cancelGame, endGame, newGame]);

	// // DEV
	// window.animate = animate;
	// window.newGame = newGame;
	// window.cancelGame = cancelGame;
	// window.spinComplete = spinComplete;
	// window.endGame = endGame;

	// ======================================================================
	// RENDER
	// ======================================================================

	return (
		<div
			className={clsx(style.welcome_tv, style['market_' + props.market], {
				[style.hidden]: gameState === 'hidden'
			})}
		>
			<div className={style.spinner_wrap}>
				<Spinner
					spinnerType="welcome"
					spinnerData={props.spinnerData}
					spinnerCallback={spinnerCallback}
					spinnerState={spinnerState}
					spinnerVars={spinnerVars}
				/>
			</div>
			<div className={clsx(style.logo, style['logo_' + props.market])}></div>
			<div
				className={clsx(style.qr, {
					[style.qr_hidden]:
						props.json.config?.hideQR ||
						props.qrState !== 'ready' ||
						playState !== 'promo' ||
						socketState !== 'joinedRoom' ||
						gameState !== 'startAnimation'
				})}
			>
				<RenderQR qrState={props.qrState} qrURL={props.qrURL}></RenderQR>
			</div>
			<div
				className={clsx(style.device, style['device_' + props.market], {
					[style.device_visible]: deviceState === 'visible',
					[style.device_dropped]: dropState === 'result',
					[style.device_end]: deviceState === 'end'
				})}
			>
				<div className={style.device_copy}>{props.json.promo.product}</div>
			</div>
			<RenderResult
				resultState={resultState}
				json={props.json}
				landingFlavour={props.landingFlavour}
			></RenderResult>
			<div className={style.base}>
				<RenderBody
					playState={playState}
					gameState={gameState}
					json={props.json}
					winningFlavour={props.winningFlavour}
					prize={props.prize}
				></RenderBody>
				<div className={style.base_hashtag}>{props.json.promo.hashtag}</div>
			</div>
		</div>
	);
}

function RenderQR({ qrState, qrURL }) {
	if (qrState === 'ready') {
		return <QRCode value={qrURL} size={240} bgColor="#fff" fgColor="#000" />;
	}

	return null;
}

function RenderResult({ resultState, json, landingFlavour }) {
	return (
		<div
			className={clsx(style.result, {
				[style.result_visible]: resultState === 'visible',
				[style.result_end]: resultState === 'end'
			})}
		>
			<div className={style.result_title}>{json.game.result}</div>
			<div className={style.result_item}>{landingFlavour}</div>
		</div>
	);
}

function RenderBody({ playState, gameState, json, winningFlavour, prize }) {
	// console.log(playState, prize);
	if (playState === 'promo' && gameState === 'startAnimation') {
		return (
			<div className={style.base_inner}>
				<div className={style.base_title}>
					<div className={style.base_frame1}>{json.promo.title1}</div>
					<div className={style.base_frame2}>{json.promo.title2}</div>
					<div className={style.base_frame3}>{json.promo.title3}</div>
				</div>
				<div className={style.base_body}>{json.promo.body}</div>
			</div>
		);
	} else if (playState === 'playing') {
		return (
			<div className={style.base_inner}>
				<div className={style.base_title}>
					{json.game.title.replace('$_flavour', winningFlavour)}
				</div>
				<div className={style.base_body}>{json.game.body}</div>
			</div>
		);
	} else if (playState === 'result' && prize !== true) {
		return (
			<div className={style.base_inner}>
				<div className={style.base_title}>{json.lose.title}</div>
				<div className={style.base_body}>{json.lose.body}</div>
			</div>
		);
	} else if (playState === 'result' && prize === true) {
		return (
			<div className={style.base_inner}>
				<div className={clsx(style.base_title, style.base_title_win)}>{json.win.title}</div>
				<div className={style.base_body}>{json.win.body}</div>
			</div>
		);
	}

	return null;
}
