import cloneDeep from 'lodash-es/cloneDeep';

// ======================================================================
// HELPERS
// ======================================================================

// generate random number
export function rand(minNo, maxNo) {
	var difference = maxNo - minNo + 1;
	var randomNo = Math.floor(Math.random() * difference);
	return randomNo + minNo;
}

// radians / degrees
export function toDegrees(n) {
	return n * (180 / Math.PI);
}
export function toRadians(n) {
	return n * (Math.PI / 180);
}

// odd / even
export function isEven(n) {
	return n % 2 === 0;
}
export function isOdd(n) {
	return n % 2 === 1;
}

// clone object
export function clone(object) {
	return cloneDeep(object);
}
