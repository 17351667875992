import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { motion } from 'framer-motion';

import * as animations from '../welcome/Welcome.animations';

import style from './perso.module.scss';
import AnimatedCharacters from './AnimatedCharacters';
import { useQueryParams } from 'bat-components';


function PersoComponent() {
	const [animateText, setAnimateText] = useState(false);
	const selector = state => {
		return {
			perso: state.content.getIn(['content', 'perso']).toJS(),
			market: state.content.getIn(['content', 'market_details']).toJS()
		};
	};

	const { perso, market } = useSelector(selector);
	const { store_miid } = useQueryParams();

	const storeOverrideName = Object.keys(perso.stores).find(
		k => store_miid && store_miid.replace(/_/g, '-').toUpperCase().includes(k)
	);

	const marketStyleOverrides = getMarketStyleOverridesForPerso(market.country);
	const timeOfDay = getTimeOfDay(perso.times);
	const timeOfDayData =
		perso.default.allow_store_override &&
		perso.stores[storeOverrideName] &&
		perso.stores[storeOverrideName][timeOfDay]
			? perso.stores[storeOverrideName][timeOfDay]
			: perso.default[timeOfDay];

	const timeOfDayText = [
		{
			type: 'greeting',
			text: `${timeOfDayData.greeting_phrase.toUpperCase()} ${
				perso.default.append_recipient
					? (perso.stores[storeOverrideName] &&
							perso.stores[storeOverrideName].recipient.toUpperCase()) ||
					  perso.default.recipient.toUpperCase()
					: ''
			}`
		}
	];

	const container = {
		visible: {
			transition: {
				staggerChildren: 0.025,
				delay: 2.5
			}
		}
	};
	const brandLogo = timeOfDayData.brand.icon;

	setTimeout(() => {
		setAnimateText(true);
	}, 3500);

	return (
		<>
			<div className={style.persoWrapper} variants={animations.animVariants}>
				<div
					className={style.persoBackground}
					style={{ backgroundImage: `url('${timeOfDayData.background}')` }}
				/>
				<img alt="brand logo" src={brandLogo} className={style.persoLogo} />
				<div style={{ width: '100vw', display: 'flex', justifyContent: 'center' }}>
					<img
						alt="product"
						className={style.persoProduct}
						src={timeOfDayData.product}
						style={{ width: marketStyleOverrides ? marketStyleOverrides.productSize : '1080px' }}
					/>
				</div>
			</div>
			<motion.div
				initial="hidden"
				animate={animateText ? 'visible' : 'hidden'}
				variants={container}
				className={style.persoTextContainer}
			>
				<h3 style={{ marginBottom: marketStyleOverrides ? '2.5%' : '5%' }}>
					{`${timeOfDayData.product_title}`}
				</h3>
				{timeOfDayText.map((item, index) => {
					return <AnimatedCharacters {...item} market={market} key={index} />;
				})}
				<h2 style={{ marginTop: marketStyleOverrides ? '2.5%' : '5%' }}>
					{`${timeOfDayData.description}`}
				</h2>
			</motion.div>
		</>
	);
}

function getTimeOfDay(times) {
	const format = 'hh:mm';
	const currentTime = moment();

	let timeOFDayValue = 'morning';

	for (const value in times) {
		const startTime = moment(times[value].start, format);
		const endTime = moment(times[value].end, format);

		if (currentTime.isBetween(startTime, endTime)) {
			timeOFDayValue = value;
			break;
		}
	}

	return timeOFDayValue;
}

export function getMarketStyleOverridesForPerso(market) {
	switch (market) {
		case 'US':
			return {
				greetingFontSize: '120px',
				productSize: '900px'
			};
		case 'PL':
		case 'ZA':
			return {
				greetingFontSize: '154px',
				productSize: '1080px'
			};
		default:
			return undefined;
	}
}

export default PersoComponent;
