import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RouterTransition, AppWrapper, useSocket, useConfig, Warnings } from 'bat-components';
import { Welcome } from 'components/pages';
import welcomeSelector from '../pages/welcome/Welcome.selector';

export default function Main() {
	const dispatch = useDispatch();
	const { debug } = useConfig();
	const { listener, sendToRoom } = useSocket();
	const { slides } = useSelector(welcomeSelector);
	const gameSlideExists = Object.keys(slides).some(k => {
		return slides[k].type === 'game';
	});

	const storeId = useSelector(state => state.internal.getIn(['store', 'uuid']));
	const { warnings } = useSelector(state => ({
		warnings: state.content.getIn(['content', 'warnings'])?.toJS()
	}));

	useEffect(() => {
		if (gameSlideExists) {
			dispatch.content.generateQr();
			dispatch.content.getWinningFlavour();
		}
	}, [dispatch.content, debug, storeId, gameSlideExists]);

	useEffect(() => {
		listener('newGame', function (response) {
			dispatch.content.newGame({ sendToRoom, ...response });
		});
		listener('quitGame', function (response) {
			dispatch.content.quitGame(response);
		});
		listener('spinToWin', function (response) {
			dispatch.content.spinToWin(response);
		});
		listener('refreshNewGameTimeout', function (response) {
			dispatch.content.refreshNewGameTimeout(response);
		});

		if (debug) {
			// dev
			window.newGame = data => {
				dispatch.content.newGame({ sendToRoom, storeId: 'dev', gameId: 'devtest', ...data });
			};

			window.spinToWin = data => {
				dispatch.content.spinToWin({
					storeId: 'dev',
					gameId: 'devtest',
					rotation: 1360,
					sector: 4,
					success: false,
					...data
				});
			};
		}
	}, [dispatch.content, listener, sendToRoom, debug]);

	return (
		<AppWrapper>
			<RouterTransition>
				<Route path="/" exact component={Welcome} />
			</RouterTransition>
			<Warnings content={warnings} />
		</AppWrapper>
	);
}
